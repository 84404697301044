.my-custom-card {
    background: linear-gradient(to bottom,rgb(175, 16, 16), #facd07); /* Adjust colors as needed */
}

.link-bold {
    font-weight: bold;
}

.input-field {
    border-radius: 25px !important;
    padding-left: 30px !important;
}

.card-header {
    color: white !important;
    font-weight: bold;
}

.card-body {
    border: none;
}

/* Default styles for labels */
.left-align-label {
    color: white;
    /*text-align: left;
    margin-left: 30%;*/
    text-align: center;
    font-weight: bold;
    display: block;
    width: 100%; /* Full width by default */
}

/* Default styles for the card */
.my-custom-card {
    width: 100%; /* Full width by default */
    margin: 0 auto; /* Center the card horizontally */
}

/* Responsive styles for the ReCAPTCHA container */
.recaptcha-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-align {
    text-align: center!important;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    padding: 0.5rem 1rem;
    border: none;
}

@media (max-width: 768px) {

    .left-align-label {
        width: auto; /* Auto width for smaller screens */
        color: white;
        text-align: left;
        margin-left: 0%;
    }
    /* Adjust card width for smaller screens */
    .my-custom-card {
        max-width: 100%; /* Full width for smaller screens */
    }

    /* Adjust ReCAPTCHA container width for medium to small screens */
    .recaptcha-container {
        width: 100%; /* Full width for medium to small screens */
    }

    .input-field {
        font-size: small;
    }

    .form-align {
        text-align: left!important;
    }

    .btn-primary {
        background-color: #007bff;
        color: #fff;
        font-size: 14px !important;
        padding: 0.4rem 0.6rem !important;
        border: none;
    }
    
}

/* Style for the center-aligned labels and input fields in larger devices */
@media (min-width: 769px) {
    .form-align {
        text-align: center;
    }
    .input-field {
        width: 60% !important;
        margin-left: 20% !important;
        font-size: medium;
    }
}

/* Style for the left-aligned labels and input fields in smaller devices */
@media (max-width: 769px) {
    .form-align {
        text-align: left;
    }
    .input-field {
        width: 100%;
    }
}

/* Style for the ReCAPTCHA container */
.recaptcha-container {
    text-align: center;
    margin-top: 1rem;
    height: 80px; /* Set the desired fixed height */
    width: 100%; /* Set the width to 100% by default */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive styles for the ReCAPTCHA container */
@media (max-width: 768px) {
    .recaptcha-container {
        height: 60px; /* Adjust height for smaller screens if needed */
        width: calc(80% - 20px) !important; /* Set a percentage width for small screens */
        margin: 0 auto;
    }
}

/* Style for the login button */
.btn-primary {
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    padding: 0.5rem 1rem;
    border: none;
}

/* Style for the links */
.link-bold {
    font-weight: bold;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

#leftLoginPart {
    width: 30%;
    background-color: beige;
    padding: 50px;
}

#rightLoginPart {
    width: 70%;
}

.left-login, .right-login {
    display: inline-block;
}



