.table1-registration {
    border: 2px solid grey;
    border-collapse: collapse;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    margin: 5px 0;
}

.card-body-registration {
    background-color: white;
    border: none;    
}

/*
.card-header-registration-bg {
    background-image: url('registration-background.jpg');
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
    border-radius: 10px; /* Add border radius for a rounded effect 
    min-height: 200px; /* Set a minimum height for the container 
    width: 100% !important; /* Set the width to 100% to fill the parent container 
  }

   
  @media (max-width: 768px) {
    .card-header-registration-bg {
      min-height: 150px;
    }
  }
  
  @media (max-width: 576px) {
    .card-header-registration-bg {
      min-height: 100px; 
    }
  }
  */

.table-section-heading-registration {
    color: black;
}

.card-header-registration {
    padding: 10px;
    color: black;
    font-weight: bold;
    background-color: #F0F0F0;
}

.label-styling-registration {
    font-weight: 300;
    font-size: 16px;
}

.select-input-registration {
    text-align: left;
    font-size: 12px !important;
    font-weight: 600 !important;
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-radius: 0px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    line-height: 1.2 !important;
    /*background-color: white !important;*/
    transition: none !important;
}

.view-mode-stylings {
  font-size: 12px;
  font-weight:600;
}

/*.select-input-registration:focus {
  background-color: #F0FFFF !important; // Updated background color when focused 
  transition: none !important;
}*/

.text-left-registration {
    text-align: left !important;
}

.button-registration {
    margin-right: 15px;
}

.progress-bar {
  width: 50%; /* Initial progress bar width */
  transition: width 0.5s;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}

.progress-bar.completed {
  width: 100%; /* Completed step width */
}

/* Rounded shapes for Name and Address Details */
.table1-registration {
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
}

/* Button styling */
.button-registration {
  margin-top: 0px;
}

/* Label styling */
.label-styling-registration {
  font-weight: bold;
}

/* Centered text */
.text-center {
  text-align: center;
}

/* Additional CSS for form elements can be added as needed */



/* Button styling */
.button-registration {
  margin-top: 0px;
}

/* Label styling */
.label-styling-registration {
  font-weight: bold;
}

/* Centered text */
.text-center {
  text-align: center !important;
}

/* Progress Bar Styles */
.progress {
  height: 40px;
  background-color: #ccc;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  line-height: 40px;
  text-align: center;
  color: #fff;
}

.progress-bar.completed {
  background-color: #28a745; /* Green color for completed steps */
}

.react-tel-input {
  .form-control {
    width: inherit !important;
    font-size: 12px !important;
    color:dimgray;
    margin: 0px;
    height: 36px !important;
    padding-left: 40px !important;
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-radius: 3px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .flag-dropdown { 
    background-color: white !important;
    border: none !important;
    border-bottom: 1px solid grey !important;
    border-right: 1px solid grey !important;
    box-shadow: 0 0px 4px rgba(0, 0, 0., 0.4);
  }
}

.card-header-registration-bg {
  background-image: url('vendor5.jpg'); /* Replace 'background.jpg' with your actual image file name */
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px; /* Add padding for spacing */
  margin: 0px 20px;
  text-align: center;
}

.card-header-registration {
  background-color: rgb(230, 252, 252,0.2); /* Use rgba to set transparency (0.7 is 70% transparent) */
  padding: 10px;
  border-radius: 5px; /* Optionally add border-radius for rounded corners */
  width: auto;
}

.card-header-registration h4 {
  margin: 0;
  color: black;
  font-size: 16px;
}

.card-header-registration-steps {
  background-color: 	#D3D3D3;
  padding: 10px;
  border: 0px;
}

.registration-sub-heading {
  background-color:	#D8D8D8;
  width: 350px;
  text-align: left;
  padding: 10px 50px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.margin-settings {
  margin-top: 16px !important;
}

.form-label-styling-registration {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.8;
  /*color: #88807B;*/
  color: #787276;
}

.check-box-styling {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.form-check {
  padding: 0px !important;
}

@media (max-width:500px) {
.react-tel-input {
 .form-control {
    font-size: smaller !important;
    margin: 0px;
    height: 30px !important;
  }

  .flag-dropdown { 
    height: inherit;
  }
}

  .bank-text-content {
    font-size: 10px;
  }

  .bank-choose-file {
    font-size: 10px;

  }

  .margin-settings {
    margin-top: 10px !important;
  }

  .select-input-registration {
    font-size: 6px !important;
    font-weight: 400 !important;
  }

  .view-mode-stylings {
    font-size: 6px;
    font-weight: 400;
  }

  .registration-sub-heading {
    font-size: small;
    width:auto;
    padding: 10px;
  }

  .MuiStepLabel-labelContainer span {
    font-size: small;
  }

  .phone-input {
    color: red !important;
    font-size: smaller !important;
  }

  .card-header-registration-bg {
    padding: 25px; /* Add padding for spacing */
  }

  .card-header-registration h4 {
    margin: 0;
    color: black;
    font-size: smaller;
  }

  .card-header-registration {
    padding: 10px;
  }

  .card-header-registration-steps {
    font-size: medium !important;
  }

  .card-header-registration-label {
    font-size: medium;
    padding: 0px;
    margin: 0px;
  }

  .label-styling-registration {
    font-size:small;
  }

  .form-label-styling-registration {
    font-size: 8px;
    font-weight: 600;
    color: #787276;
    /* color: #88807B*/
}

.check-box-styling {
  font-size: 6px;
  font-weight: 400;
}
}

.stepper-number-click-registration {
  cursor: pointer;
}

.document-note-settings {
  display: inline-block; 
  margin-bottom: 0px;
  font-weight: bold;
}

.document-note-bg {
  background-color: #f2adac; 
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px 25px !important;
  font-weight: 300;
  text-align: left;
}

.form-control {
  padding: 0.375rem 0.75rem !important;
  /*transition: none !important;*/
  /*transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;*/
}

.error-message {
  color: red;
  font-size: 0.625rem;
  text-align: center;
}

.data-saved-success {
  color: green;
  font-size: 1rem;
}

.data-resetted-success {
  color: red;
  font-size: 1rem;
}

.display-inline-block {
  display: inline-block;
}

/* YourComponent.css */

.custom-select-container {
  position: relative;
}

.custom-select-wrapper {
  position: relative;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.custom-caret {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #AED6F1;
}

.sub-headings h6 {
  margin: 0px;
}

.sub-headings {
  background-color: #89CFF0;
  width:fit-content;
  border-radius: 4px 30px;
  padding: 6px 20px 8px;
}

.custom-select-monospace {
  font-family: monospace; /* Use a fixed-width font */
} 
/*button, input, optgroup, select, textarea {
  transition: none !important; 
}*/

/* Add these styles to your CSS */

/*.tooltip {
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
*/