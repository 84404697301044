.bg-login {
    height: 100vh;
    width: 100vw;
    background-image: url(./train.jpg);
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    /*filter: blur(1.2px);*/
    opacity: 0.6;
    /* height: 100vh; */
    box-sizing: border-box;
}

.bg-row {
    padding: 0;
    margin: 0;
}

.left-login {
    width: 30%;
    margin: 1.25rem;
}

.right-login {
    /* width: 100%; */

    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    /*filter: blur(1.2px);*/
    opacity: 0.6;
    /* height: 100vh; */
    box-sizing: border-box;
}

.test,
.container-fluid,
.row {
    width: 100%;
}

.logo-title-1 {
    color: #b50909;
}

.logo-title-2 {
    color: #f9a603;
}

.logo-title-1,
.logo-title-2 {
    margin-top: 5px;
}

.logo-style-login {
    height: 80px;
    width: 320px;
}

.login-patil-icons {
    display: flex;
    font-size: 1rem;
}

.login-facebook-icon {
    color: #3b5998;
}

.login-linkedin-icon {
    color: #0072b1;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.login-arrow-position {
    position: relative;
    bottom: 20%;
    left: 30%;
}

.login-arrow-icon {
    color: #0072b1;
    animation: blink 1s infinite;
    font-size: 1.5rem;
}

.login-phone-icon {
    color: green;
    margin-right: 20px;
}

.custom-linkedin-icon {
    display: inline-block;
    position: relative;
}

.custom-linkedin-icon .fa-square {
    font-size: 1.5rem;
    /* Adjust the size as needed */
    color: #0077B5;
    /* LinkedIn blue color */
}

.custom-linkedin-icon i {
    position: absolute;
    font-size: 1.5rem;
    /* Adjust the size as needed */
    color: #0A66C2;
    /* LinkedIn blue color */
}

.g {
    background: linear-gradient(to bottom, #DB4437, #DB4437, #F4B400, #0F9D58, #4285F4);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

a {
    text-decoration: none;
}

.icon-space {
    margin-right: 20px;
}

.login-patil-container {
    background-color: rgba(0, 0, 0, 0.8);
    width: fit-content;
    padding: 40px;
    padding-top: 0px;
    border-radius: 15px;
    height: fit-content;
}

.login-card-header {
    color: white;
    font-weight: bold;
    background-color: #4169E1;
    padding: 5px;
    position: relative;
    bottom: 20px;
    border-radius: 8px;
}

.card-body {
    background-color: inherit;
    border: none;
}

.login-left-align-label {
    color: white;
    /*text-align: left;
    margin-left: 30%;*/
    font-weight: bold;
    display: block;
    width: 100%;
    /* Full width by default */
    text-align: left;
    padding-left: 10px;
}

.login-input-field {
    border-radius: 25px;
    padding-left: 30px;
    /* margin-bottom: 10px; */
}

.patil-address-company-name {
    font-weight: bold;
    margin: 0px;
}

.patil-address-details {
    font-weight: 500;
    margin: 0px;
    font-size: 1rem;
}

.handle-logo {
    cursor: pointer;
}

.patil-group-quotation {
    font-weight: bold;
    font-style: oblique;
    font-size: 1rem;
    color: #7e41e1;
}

.patil-footer {
    height: 5%;
    background-color: rgb(45, 1, 1);
    /* width: 100vw; */
    padding: 10px;
}

.patil-footer-description {
    color: white;
    margin-right: 20px;
    font-size: 1rem;
}

@media (max-width: 768px) {

    .login-left-align-label {
        width: auto;
        /* Auto width for smaller screens */
        color: white;
        text-align: left;
        margin-left: 0%;
    }

    .login-input-field {
        font-size: small;
    }

    .patil-footer-description {
        font-size: 0.5rem;
    }

    .login-patil-icons {
        font-size: 0.5rem;
    }

    .login-arrow-icon {
        font-size: 0.75rem;
    }
}



/* Captcha */

.captcha-container {
    position: relative;
    top: 30px;
}

.wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 1em 2em;
    gap: 5%;
    /* margin-top: 100px; */
}

canvas {
    /* border: 2px solid crimson;  */
    border-radius: 20px;
    background-color: white;
    padding: 0;
}

button#reload-button {
    font-size: 1rem;
    width: 4.6em;
    height: 2em;
    background-color: green;
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 0.4em;
    color: #ffffff;
}

button#reload-button:hover {
    background-color: rgb(46, 153, 46);
}

.wrapper input[type='text'] {
    font-family: "roboto";
    font-size: 1rem;
    width: 100%;
    padding: 6px;
    border: none;
    font-weight: 500;
    outline: none;
    padding-left: 10px;
    /* border: 2px solid crimson;  */
    border-radius: 20px;
}

button#submit-button {
    width: 80px;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    margin-top: 1em;
    padding: 0.2em 0;
    border-radius: 0.4em;
    background-color: green;
    cursor: pointer;
}

button#submit-button:hover {
    background-color: rgb(53, 175, 53);
}

/* Spinner */
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}