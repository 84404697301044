.vendor-status-container {
    border: 2px solid blue;
    height: fit-content;
    width: 50%; 
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

.vendor-details-table {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .table-alignment {
    border-collapse: collapse;
    width: 50%; 
    margin-top: 10px;
  }
  
  th.header-cell {
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #ddd; /* Border color */
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2; /* Header background color */
  }

  .table-content-label {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .table-content-values {
    font-size: 0.8rem;
    font-weight: 600;
  }
  