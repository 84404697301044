.collapsed-border {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .collapsed-border th {
    border: 1px 1px;
    padding: 0.275rem;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
  }

  .collapsed-border td {
    border: 0px 1px;
    padding: 0.275rem;
  }
  
  .collapsed-border tr {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .corrected-value-input {
    width: 100%;
    height: 1.8rem;
    line-height: 1.8rem;
    margin: 0.625rem 0rem;
    padding: 0rem 0.625rem;
    font-size: 0.75rem;
  }

  .reason-for-discrepacy-textfield {
    width: 80%;
    margin: 0.625rem 0rem;
    padding: 0.375rem 0.625rem;
    font-size: 0.75rem;
    resize:none;
  }

  .side-heading {
    font-size: 14px;
    padding: 0.375rem 0.625rem !important;
  }